import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Welcome to my portfolio page`}</h1>
    <p><strong parentName="p">{`Hello, I'm `}<em parentName="strong">{`Sebastian Paintner`}</em></strong></p>
    <p>{`a `}<strong parentName="p">{`fullstack developer`}</strong>{` but mostly focused on the `}<strong parentName="p">{`frontend`}</strong>{`, with already some years of real life work experience.`}</p>
    <p>{`I was interested in `}<strong parentName="p">{`coding`}</strong>{` since early in school. Back then I was trying to create little games or
just play around with everything that I found interesting.`}</p>
    <p>{`After a little detour of studying `}<strong parentName="p">{`electrical engineering`}</strong>{` it took me back to programming in my
bachelors thesis. There I did a case study for the `}<strong parentName="p">{`Münchner Verkehrs Gesellschaft (MVG)`}</strong>{`, of how
to monitor the inside of their trains and present it to the control room.`}</p>
    <p>{`From there on I started working as a `}<strong parentName="p">{`web engineer`}</strong>{` and went pretty quick into the direction of
`}<strong parentName="p">{`frontend engineering`}</strong>{`. After almost 7 years of working in this field I'm longing for some change.
I got more and more interested in combining my skills in programming with other fields.
Thats why I started to do some projects that are more than just a simple website.`}</p>
    <p>{`Getting more into these projects I found the study program of `}<strong parentName="p">{`design computation`}</strong>{` and it sounds
exactly what I was looking for.`}</p>
    <p>{`So far about me. Now I want to present to you some selected projects which I think are relevant for
applying to this study program.`}</p>
    <p><strong parentName="p">{`The projects:`}</strong></p>
    <ol>
      <li parentName="ol">{`An augmented reality web app I made for `}<a parentName="li" {...{
          "href": "https://www.slanted.de/"
        }}>{`slanted`}</a>{`.`}</li>
      <li parentName="ol">{`An artwork generator website made with `}<a parentName="li" {...{
          "href": "https://p5js.org/"
        }}>{`p5js`}</a>{`.`}</li>
      <li parentName="ol">{`And a light installation made out of a mate box.`}</li>
    </ol>
    <p>{`All these projects combine programming with other disciplines, like `}<strong parentName="p">{`art`}</strong>{` or `}<strong parentName="p">{`electrical engineering`}</strong>{`.
This makes them really interesting for me!`}</p>
    <p><strong parentName="p">{`How to navigate this page:`}</strong></p>
    <p>{`Every project is presented in detail over the next pages.
Getting to the `}<strong parentName="p">{`next page`}</strong>{` can be done either:`}</p>
    <ul>
      <li parentName="ul">{`on `}<strong parentName="li">{`desktop`}</strong>{` with the `}<strong parentName="li">{`arrow keys`}</strong>{` of your keyboard`}</li>
      <li parentName="ul">{`on `}<strong parentName="li">{`mobile`}</strong>{` by `}<strong parentName="li">{`clicking`}</strong>{` on the `}<strong parentName="li">{`right`}</strong>{` or `}<strong parentName="li">{`left`}</strong>{` side of your screen (just like in instagram stories)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      